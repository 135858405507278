import request from '@/utils/request'

// api地址
const api = {
  page: '/sys/dictdata/page'
}

/**
 * 查询列表
 * @param val 参数
 * @returns {Promise<Array>}
 */
export function querydictdata(val) {
  return new Promise((resolve, reject) => {
    const params = { dictCode: val }
    request.get(api.page, { params: params }).then(res => {
      resolve(res.data)
    }).catch(e => {
      reject(e)
    })
  })
}
